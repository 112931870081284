import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from "vue-i18n";

require('./assets/css/style.css')

const i18n = createI18n({
    locale: "en",
    messages: {
        ko: {
            // Nav Text
            nav_home: "홈",
            nav_service: "서비스",
            nav_project: "프로젝트",
            nav_token: "토큰",
            nav_dividend: "배당",
            nav_wallet: "지갑",

            // Home Text
            home_sub_title: "투자의 새로운 정의, 혁신적인 이익 분배를 통한 자본",
            home_sub_text: "블록체인 기술과 STO를 활용하여 전 세계적으로 투자 기회를 제공합니다. 실시간 관리와 투명성을 통해 전통적인 투자 방법을 혁신하고, 모두에게 열린 지속 가능한 투자 생태계를 지향합니다.",
            home_whitepaper: "백서",
            home_foot_text: "전 세계의 회사들로부터 도구 및 통합을 제공합니다",

            // Vision Text
            vision_point_text: "블록체인 기술과 STO를 활용하여 전 세계적으로 투자 기회를 제공합니다. 실시간 관리와 투명성을 통해 전통적인 투자 방법을 혁신하고, 모두에게 열린 지속 가능한 투자 생태계를 지향합니다.",
        
            // Service Text
            service_card_title_01: "디지털 자산 관리",
            service_card_text_01: "블록체인을 통해 실시간 추적 및 관리로 자산 보안과 투명성을 강화합니다.",
            service_card_title_02: "투자자 투표 시스템",
            service_card_text_02: "블록체인을 통해 투명하고 위변조 방지된 투자자 참여를 통해 의사 결정 프로세스에 참여합니다.",
            service_card_title_03: "자동 이익 분배",
            service_card_text_03: "스마트 계약을 활용하여 투자자에게 효율적이고 정확한 이익 분배를 제공합니다.",
            service_card_title_04: "토큰화 자산 거래",
            service_card_text_04: "토큰화된 부동산, 예술 및 지적 재산의 쉬운 거래를 통해 투자 유동성을 높입니다.",
            service_card_title_05: "투명한 감사 추적",
            service_card_text_05: "거래의 명확하고 변경할 수 없는 기록을 제공하여 투자자 신뢰와 규제 준수를 강화합니다.",
            service_card_title_06: "토큰 거래 및 유동성",
            service_card_text_06: "플랫폼의 투자 유동성을 개선하기 위해 쉬운 토큰 거래를 허용합니다.",
        },
        en: {
            // Nav Text
            nav_home: "Home",
            nav_service: "Service",
            nav_project: "Project",
            nav_token: "Token",
            nav_dividend: "Dividend",
            nav_wallet: "Wallet",

            // Home Text
            home_sub_title: "Investing Redefined, Innovative Profit Sharing Through Equity",
            home_sub_text: "We leverage blockchain technology and STOs to provide transparent and equal investment opportunities worldwide. Innovating traditional investment methods through real-time management and transparency, we aim for a sustainable investment ecosystem open to all.",
            home_whitepaper: "Whitepaper",
            home_foot_text: "Powering tools and integrations from companies all around the world",

            // Vision Text
            vision_point_text: "We leverage blockchain technology and STOs to provide transparent and equal investment opportunities worldwide. Innovating traditional investment methods through real-time management and transparency, we aim for a sustainable investment ecosystem open to all.",

            // Service Text
            service_card_title_01: "Digital Asset Management",
            service_card_text_01: "Enhances asset security and transparency with real-time tracking and management through blockchain.",
            service_card_title_02: "Investor Voting System",
            service_card_text_02: "Facilitates transparent and tamper -proof investor participation in decision-making processes via blockchain.",
            service_card_title_03: "Automated Profit Distribution",
            service_card_text_03: "Utilizes smart contracts for efficient and accurate profit distribution to investors.",
            service_card_title_04: "Tokenized Asset Trading",
            service_card_text_04: "Enables easy trading of tokenized real estate, art, and intellectual properties, increasing investment liquidity.",
            service_card_title_05: "Transparent Audit Trail",
            service_card_text_05: "Provides a clear, immutable record of transactions, enhancing investor trust and regulatory compliance.",
            service_card_title_06: "Token Exchange and Liquidity",
            service_card_text_06: "Allows easy token exchanges, improving investment liquidity on the platform.",
        },
        zh: {
            // Nav Text
            nav_home: "主页",
            nav_service: "服务",
            nav_project: "项目",
            nav_token: "代币",
            nav_dividend: "分红",
            nav_wallet: "钱包",

            // Home Text
            home_sub_title: "重新定义投资，通过股权创新的利润分享",
            home_sub_text: "我们利用区块链技术和STO为全球提供透明和平等的投资机会。通过实时管理和透明度创新传统投资方法，我们致力于打造一个面向所有人的可持续投资生态系统。",
            home_whitepaper: "白皮书",
            home_foot_text: "来自世界各地公司的工具和集成",

            // Vision Text
            vision_point_text: "我们利用区块链技术和STO为全球提供透明和平等的投资机会。通过实时管理和透明度创新传统投资方法，我们致力于打造一个面向所有人的可持续投资生态系统。",
        
            // Service Text
            service_card_title_01: "数字资产管理",
            service_card_text_01: "通过区块链实现实时跟踪和管理，增强资产安全性和透明度。",
            service_card_title_02: "投资者投票系统",
            service_card_text_02: "通过区块链实现透明和防篡改的投资者参与决策过程。",
            service_card_title_03: "自动利润分配",
            service_card_text_03: "利用智能合约为投资者提供高效和准确的利润分配。",
            service_card_title_04: "代币化资产交易",
            service_card_text_04: "便于交易代币化的房地产、艺术品和知识产权，提高投资流动性。",
            service_card_title_05: "透明审计追踪",
            service_card_text_05: "提供清晰、不可更改的交易记录，增强投资者信任和合规性。",
            service_card_title_06: "代币交易和流动性",
            service_card_text_06: "允许平台上进行简单的代币交易，提高投资流动性。",

        },
        ja: {
            // Nav Text
            nav_home: "ホーム",
            nav_service: "サービス",
            nav_project: "プロジェクト",
            nav_token: "トークン",
            nav_dividend: "配当",
            nav_wallet: "ウォレット",

            // Home Text
            home_sub_title: "投資の再定義、革新的な利益分配を通じた資本",
            home_sub_text: "ブロックチェーン技術とSTOを活用して、世界中に透明で平等な投資機会を提供します。リアルタイムの管理と透明性を通じて、伝統的な投資方法を革新し、すべての人に開かれた持続可能な投資エコシステムを目指します。",
            home_whitepaper: "ホワイトペーパー",
            home_foot_text: "世界中の企業からのツールと統合",

            // Vision Text
            vision_point_text: "ブロックチェーン技術とSTOを活用して、世界中に透明で平等な投資機会を提供します。リアルタイムの管理と透明性を通じて、伝統的な投資方法を革新し、すべての人に開かれた持続可能な投資エコシステムを目指します。",
            
            // Service Text
            service_card_title_01: "デジタル資産管理",
            service_card_text_01: "ブロックチェーンを活用して、リアルタイムの追跡と管理により資産のセキュリティと透明性を強化します。",
            service_card_title_02: "投資家投票システム",
            service_card_text_02: "ブロックチェーンを活用して、透明で改ざん防止された投資家参加を通じて意思決定プロセスに参加します。",
            service_card_title_03: "自動利益分配",
            service_card_text_03: "スマートコントラクトを活用して、効率的で正確な利益分配を投資家に提供します。",
            service_card_title_04: "トークン化資産取引",
            service_card_text_04: "トークン化された不動産、芸術、知的財産の簡単な取引を通じて、投資の流動性を高めます。",
            service_card_title_05: "透明な監査追跡",
            service_card_text_05: "取引の明確で変更できない記録を提供し、投資家の信頼と規制遵守を強化します。",
            service_card_title_06: "トークン取引と流動性",
            service_card_text_06: "プラットフォームの投資流動性を向上させるため、簡単なトークン取引を許可します。",
        
        },
    },
});

const app = createApp(App);

app.use(router).use(i18n).mount("#app");